import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import '../css/deposit.css';
import '../css/Deposit_responsive.css';
import ProgressInputWalletAddress from '../Components/progressInputWalletAddress.js';
import greydashedline from '../images/grey dashed line.png';
import backbtn from '../images/Back Button.png';
import guideicon from '../images/Guide Icon.png';
import three from '../images/3.png';
import four from '../images/4.png';
import yellowline from '../images/yellow line.png';
import verticalline from '../images/vertical line.png';
import floatingicon from '../images/Floating Icon.png';
import fixedicon from '../images/Fixed Icon.png';
import etaicon from '../images/ETA Icon.png';
import {toast, Bounce } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import green from '../images/Not Required.png';
import yellow from '../images/On Occassion.png';
import red from '../images/Required.png';
import darkgreen from '../images/Rarely Required.png';


const Deposit=()=>{

    const navigate = useNavigate();

    const [getextravisibility, setGetExtraVisibility]=useState(false);
    const [refextravisibility, setRefExtraVisibility]=useState(false);
    const [recipientaddress, setRecipientAddress]=useState("");
    const [refundaddress, setRefundAddress]=useState("");
    const [recipientextraid, setRecipientExtraId]=useState("");
    const [refundextraid, setRefundExtraId]=useState("");
    const [btntext, setBtnText]=useState("Proceed To Exchange");
    const [email, setEmail]=useState("");
    const [recipientaddressvalidation, setRecipientAddressValidation]=useState(false);
    const [recipientrefaddressvalidation, setRecipientRefaddressValidation]=useState(false);
    const [recipientaddressvalidationtext, setRecipientAddressValidationText]=useState("");
    const [recipientrefaddressvalidationtext, setRecipientRefaddressValidationText]=useState("");
    const [emailValidation, setEmailValidation]=useState(true);
    const [isChecked, setIsChecked] = useState(false);
    const [isCheckedValidation, setIsCheckedValidation] = useState(true);
    const [receiveAmount, setReceiveAmount]= useState(sessionStorage.getItem("local_get_amount"));

    const handlePaste = () => {
        return navigator.clipboard.readText()
          .then((text) => {
            return text;  // Return the clipboard text
          })
          .catch((err) => {
            return '';  // Return an empty string or handle the error as needed
          });
      };

    const handleToastError=(toastErrorTime, toastErrorMessage)=>{
       toast.dismiss();
       toast.error(
        <div>
            <strong>Something went wrong</strong>
            <p style={{fontSize:"13px", color:"#888787"}}>{toastErrorMessage}</p>
        </div>
        ,
        {
            position: "top-right",
            autoClose: toastErrorTime,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            transition: Bounce,
        });
    }

    function formatTo15Chars(input, length) {
        // Step 1: Convert to string if it's a number
        let value = typeof input === 'number' ? input.toString() : input;
      
        // Step 2: Trim the string to a maximum of 15 characters (including decimal point)
        value = value.slice(0, length);
      
        return value;
      }

    let apiInterval;
    let handeonetimeerrorstate;

    const handleOneTimeErrorState=(state)=>{
        handeonetimeerrorstate=state;
    }

    const rateApiCall=(url)=>{
        apiInterval = setInterval(async ()=>{
            try {
                const options={
                    method:"POST",
                    headers:{
                      "Content-Type":"application/json"
                    },
                    body:JSON.stringify({
                        sell: sessionStorage.getItem("local_send_ticker"),
                        get:sessionStorage.getItem("local_get_ticker"),
                        amount:sessionStorage.getItem("local_send_amount"),
                        exchangetype:sessionStorage.getItem("local_exchange_type")
                    })
                  }
                const response=await fetch(url, options);
                const data=await response.json(response);
                if(data.price==0){
                    if(data.message=="amount_not_in_range"){
                        if(!handeonetimeerrorstate){
                            handleToastError(false, "Deposit amount is below minimum, Please increase the you send amount");
                            handleOneTimeErrorState(true);
                        }
                    }
                    handleToastError(false, data.message);
                }else{
                    let price=parseFloat(data.price);
                    setReceiveAmount(price.toFixed(7));
                    sessionStorage.setItem("local_get_amount", price.toFixed(7));
                    handleOneTimeErrorState(false);
                }
            } catch (error) {
                if(!handeonetimeerrorstate){
                    handleToastError(false, "Please check your connection and try reloading the page.")
                    handleOneTimeErrorState(true);
                }
            }
        },4000);
    }
 
    useEffect(()=>{
        if(sessionStorage.getItem("local_send_index")=="undefined" || sessionStorage.getItem("local_get_index")=="undefined"){
            navigate("/best_crypto_to_crypto_exchange");
        }
        const getCurrencies=async()=>{
            const url=process.env.REACT_APP_URL+"/currencies";
            const response = await fetch(url);
            const data = await response.json(response);
            data.map((coin, index)=>{
                if(sessionStorage.getItem("local_get_index")==index && coin.isExtraIdSupported){
                    setGetExtraVisibility(true)
                }
                if(sessionStorage.getItem("local_send_index")==index && coin.isExtraIdSupported){
                    setRefExtraVisibility(true)
                }
            })
        }
        getCurrencies();
        if(sessionStorage.getItem("local_exchange_name")=="changelly" && sessionStorage.getItem("local_exchange_type")=="Floating"){
            rateApiCall(process.env.REACT_APP_URL+"/changelly/price");
        }else if(sessionStorage.getItem("local_exchange_name")=="changenow" && sessionStorage.getItem("local_exchange_type")=="Floating"){
            rateApiCall(process.env.REACT_APP_URL+"/changenow/price");
        }else if(sessionStorage.getItem("local_exchange_name")=="simpleswap" && sessionStorage.getItem("local_exchange_type")=="Floating"){
            rateApiCall(process.env.REACT_APP_URL+"/simpleswap/price");
        }else if(sessionStorage.getItem("local_exchange_name")=="changehero" && sessionStorage.getItem("local_exchange_type")=="Floating"){
            rateApiCall(process.env.REACT_APP_URL+"/changehero/price");
        }else if(sessionStorage.getItem("local_exchange_name")=="exolix" && sessionStorage.getItem("local_exchange_type")=="Floating"){
            rateApiCall(process.env.REACT_APP_URL+"/exolix/price");
        }else if(sessionStorage.getItem("local_exchange_name")=="letsexchange" && sessionStorage.getItem("local_exchange_type")=="Floating"){
            rateApiCall(process.env.REACT_APP_URL+"/letsexchange/price");
        }else if(sessionStorage.getItem("local_exchange_name")=="stealthex" && sessionStorage.getItem("local_exchange_type")=="Floating"){
            rateApiCall(process.env.REACT_APP_URL+"/stealthex/price");
        }else if(sessionStorage.getItem("local_exchange_name")=="godex" && sessionStorage.getItem("local_exchange_type")=="Floating"){
            rateApiCall(process.env.REACT_APP_URL+"/godex/price");
        }
        window.scrollTo(0, 0);
        return () => clearInterval(apiInterval); // Cleanup on component unmount or when isRunning changes     
    },[]);

    function calculateTimeStamp(){
        const currentDate = new Date();
        const timestamp = currentDate.getTime();
        if(sessionStorage.getItem("local_exchange_type")=="Floating"){
            return (timestamp + (1*60*60*1000));
        }else{
            return (timestamp + (15*60*1000));
        }
    }

    //Data storage and navigation after successful exchange creation
    function dataStorageAndNavigation(transactionID, depositAddress, recipientAddress, depositExtraID, timerValue, depositStatus){
        sessionStorage.setItem("ordertrackerid", transactionID);
        sessionStorage.setItem("depositaddress", depositAddress);
        sessionStorage.setItem('recipientaddress', recipientAddress);
        sessionStorage.setItem('depositExtraID', depositExtraID);
        sessionStorage.setItem('timerValue', timerValue);
        sessionStorage.setItem('depositstatus', depositStatus);
        navigate("/submit");
    }

   async function performSwap(){

    setRecipientAddressValidation(false);
    setRecipientRefaddressValidation(false);
    setEmailValidation(true);
    setIsCheckedValidation(true);

        // const validateWalletAddress=async(curr, address, extraid)=>{

        //     const url=process.env.REACT_APP_URL+"/validate/wallet_address";
        //     const options={
        //         method:"POST",
        //         headers:{
        //             "Content-Type":"application/json"
        //         },
        //         body:JSON.stringify({curr:curr, address: address, extraid:extraid})
        //     }

        //     const response=await fetch(url, options);
        //     const result=await response.json();
        //     return result.result;
        // }

        // const refaddress= await validateWalletAddress(sessionStorage.getItem("local_send_ticker"),refundaddress, refundextraid);
        // const recpaddress=await validateWalletAddress(sessionStorage.getItem("local_get_ticker"), recipientaddress , recipientextraid);
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        let emailValidationVar=null;
        if(email==""){
            emailValidationVar=true;
        }else{
             emailValidationVar=regex.test(email);
             setEmailValidation(regex.test(email));
        }

        const exchangeApiCall=async(url, name)=>{

            if(!recipientaddress=="" && !recipientaddress==" " && !refundaddress=="" && !refundaddress==" " && emailValidationVar==true && isChecked==true){
            try {
                console.log("I am called");
                const options={
                    method:"POST",
                    headers:{
                      "Content-Type":"application/json"
                    },
                    body:JSON.stringify({
                        sell: sessionStorage.getItem("local_send_ticker"),
                        get:sessionStorage.getItem("local_get_ticker"),
                        sellname:sessionStorage.getItem("local_send_crypto_name"), 
                        getname:sessionStorage.getItem("local_get_crypto_name"), 
                        selllogo:sessionStorage.getItem("local_send_crypto_logo"),
                        getlogo:sessionStorage.getItem("local_get_crypto_logo"),
                        amount:sessionStorage.getItem("local_send_amount"),
                        recieving_Address:recipientaddress,
                        refund_Address:refundaddress,
                        email:email,
                        rateId:sessionStorage.getItem("local_rate_id"),
                        extraid:recipientextraid,
                        refextraid:refundextraid,
                        expirytime: calculateTimeStamp()
                    })
                  }
                const response=await fetch(url, options);
                const data=await response.json(response);
                console.log(data);
                switch(name){
                    case "changelly":
                        if(data.transaction_id){
                            dataStorageAndNavigation(data.transaction_id, data.deposit_address, data.recipient_address, data.deposit_extraid, 1, 1);
                        }else{
                            if(data.error.message=="rateId was expired or already used. Use method getFixRate to generate new rateId"){                               
                                handleToastError( 5000, "Fixed rate expired.");
                            }else if(data.error.message=="Address is not valid"){
                                handleToastError( 5000, "Please recheck recipient and refund wallet addresses.");
                            }
                        }
                    break;

                    case "changenow":
                        if(data.transaction_id){
                            dataStorageAndNavigation(data.transaction_id, data.deposit_address, data.recipient_address, data.deposit_extraid, 1, 1);
                        }else{
                            if(data.error=="rate_id_not_found_or_expired"){
                                handleToastError( 5000, "Fixed rate expired.");
                            }else if(data.error=="not_valid_address"){
                                handleToastError( 5000, "Please recheck recipient and refund wallet addresses.");
                            }else{
                                handleToastError( false, "Please check your connection and try reloading the page.");
                            }
                        }
                    break;

                    case "changehero":
                        if(data.transaction_id){
                            dataStorageAndNavigation(data.transaction_id, data.deposit_address, data.recipient_address, data.deposit_extraid, 1, 1);
                        }else{
                            if(data.error.message=="expired rate id"){
                                handleToastError( 5000, "Fixed rate expired.");
                            }else if(data.error.message=="Invalid address"){
                                handleToastError( 5000, "Please recheck recipient and refund wallet addresses.");
                            }else{
                                handleToastError( false, "Please check your connection and try reloading the page.");
                            }
                        }
                    break;

                    case "letsexchange":
                        if(data.transaction_id){
                            dataStorageAndNavigation(data.transaction_id, data.deposit_address, data.recipient_address, data.deposit_extraid, 1, 1);
                        }else{
                            if(data.error=="Fixed rate timeout expired!"){
                                handleToastError( 5000, "Fixed rate expired.");
                            }else if(data.error.validation.withdrawal=="Invalid destination address."){
                                handleToastError( 5000, "Please recheck recipient and refund wallet addresses.");
                            }else{
                                handleToastError( false, "Please check your connection and try reloading the page.");
                            }
                        }
                    break;

                    case "stealthex":
                        if(data.transaction_id){
                            dataStorageAndNavigation(data.transaction_id, data.deposit_address, data.recipient_address, data.deposit_extraid, 1, 1);
                        }else{
                            if(data.err.details=="Received expired or nonexistent rate id"){
                                handleToastError( 5000, "Fixed rate expired.");
                            }else if(data.err.details=="Invalid address"){
                                handleToastError( 5000, "Please recheck recipient and refund wallet addresses.");
                            }else{
                                handleToastError( false, "Please check your connection and try reloading the page.");
                            }
                        }
                    break;

                    case "godex":
                        if(data.transaction_id){
                            dataStorageAndNavigation(data.transaction_id, data.deposit_address, data.recipient_address, data.deposit_extraid, 1, 1);
                        }else{
                            if(data.validation.withdrawal[0]=="Invalid destination address."){
                                handleToastError( 5000, "Please recheck recipient and refund wallet addresses.");
                            }else{
                                handleToastError( false, "Please check your connection and try reloading the page.");
                            }
                        }
                    break;

                    case "exolix":
                        if(data.transaction_id){
                            dataStorageAndNavigation(data.transaction_id, data.deposit_address, data.recipient_address, data.deposit_extraid, 1, 1);
                        }else{
                            if(data.error=="Invalid withdrawal address"){
                                handleToastError( 5000, "Please recheck recipient and refund wallet addresses.");
                            }else{
                                handleToastError( false, "Please check your connection and try reloading the page.");
                            }
                        }
                    break;

                    case "simpleswap":
                        if(data.transaction_id){
                            dataStorageAndNavigation(data.transaction_id, data.deposit_address, data.recipient_address, data.deposit_extraid, 1, 1);
                        }else{
                            if(data.description=="Validation of 'address_to' failed"){
                                handleToastError( 5000, "Please recheck recipient and refund wallet addresses.");
                            }else{
                                handleToastError( false, "Please check your connection and try reloading the page.");
                            }
                        }
                    break;
                    
                }
            } catch (error) {
                handleToastError( false, "Please check your connection and try reloading the page.");
            }
            setBtnText("Proceed To Exchange");
            }else{
                setBtnText("Proceed To Exchange");

                if(!isChecked){
                    setIsCheckedValidation(isChecked);
                }

                if(!emailValidationVar){
                    setEmailValidation(false);
                }

                if(recipientaddress=="" || recipientaddress==" "){
                    setRecipientAddressValidation(true);
                    setRecipientAddressValidationText("Recipient wallet address is required");
                }
                // else{
                //     if(!recpaddress){
                //         setRecipientAddressValidation(true);
                //         setRecipientAddressValidationText("Invalid recipient wallet address");
                //     }
                // }
                if(refundaddress=="" || refundaddress==" "){
                    setRecipientRefaddressValidation(true);
                    setRecipientRefaddressValidationText("Refund wallet address is required");
                }
                // else{
                //     if(!refaddress){
                //         setRecipientRefaddressValidation(true);
                //         setRecipientRefaddressValidationText("Invalid refund wallet address");
                //     }
                // }
            }
        }


        if(sessionStorage.getItem("local_exchange_name")=="changelly" && sessionStorage.getItem("local_exchange_type")=="Floating"){
            exchangeApiCall(process.env.REACT_APP_URL+"/createTransaction/changelly/float", "changelly");
        }else if(sessionStorage.getItem("local_exchange_name")=="changenow" && sessionStorage.getItem("local_exchange_type")=="Floating"){
            exchangeApiCall(process.env.REACT_APP_URL+"/createTransaction/changenow/float", "changenow");
        }else if(sessionStorage.getItem("local_exchange_name")=="simpleswap" && sessionStorage.getItem("local_exchange_type")=="Floating"){
            exchangeApiCall(process.env.REACT_APP_URL+"/createTransaction/simpleswap/float", "simpleswap");
        }else if(sessionStorage.getItem("local_exchange_name")=="changehero" && sessionStorage.getItem("local_exchange_type")=="Floating"){
            exchangeApiCall(process.env.REACT_APP_URL+"/createTransaction/changehero/float", "changehero");
        }else if(sessionStorage.getItem("local_exchange_name")=="exolix" && sessionStorage.getItem("local_exchange_type")=="Floating"){
            exchangeApiCall(process.env.REACT_APP_URL+"/createTransaction/exolix/float", "exolix");
        }else if(sessionStorage.getItem("local_exchange_name")=="letsexchange" && sessionStorage.getItem("local_exchange_type")=="Floating"){
            exchangeApiCall(process.env.REACT_APP_URL+"/createTransaction/letsexchange/float", "letsexchange");
        }else if(sessionStorage.getItem("local_exchange_name")=="stealthex" && sessionStorage.getItem("local_exchange_type")=="Floating"){
            exchangeApiCall(process.env.REACT_APP_URL+"/createTransaction/stealthex/float", "stealthex");
        }else if(sessionStorage.getItem("local_exchange_name")=="godex" && sessionStorage.getItem("local_exchange_type")=="Floating"){
            exchangeApiCall(process.env.REACT_APP_URL+"/createTransaction/godex/float", "godex");
        }else if(sessionStorage.getItem("local_exchange_name")=="changelly" && sessionStorage.getItem("local_exchange_type")=="Fixed"){
            exchangeApiCall(process.env.REACT_APP_URL+"/createTransaction/changelly/fixed", "changelly");
        }else if(sessionStorage.getItem("local_exchange_name")=="changenow" && sessionStorage.getItem("local_exchange_type")=="Fixed"){
            exchangeApiCall(process.env.REACT_APP_URL+"/createTransaction/changenow/fixed", "changenow");
        }else if(sessionStorage.getItem("local_exchange_name")=="exolix" && sessionStorage.getItem("local_exchange_type")=="Fixed"){
            exchangeApiCall(process.env.REACT_APP_URL+"/createTransaction/exolix/fixed", "exolix");
        }else if(sessionStorage.getItem("local_exchange_name")=="simpleswap" && sessionStorage.getItem("local_exchange_type")=="Fixed"){
            exchangeApiCall(process.env.REACT_APP_URL+"/createTransaction/simpleswap/fixed", "simpleswap");
        }else if(sessionStorage.getItem("local_exchange_name")=="changehero" && sessionStorage.getItem("local_exchange_type")=="Fixed"){
            exchangeApiCall(process.env.REACT_APP_URL+"/createTransaction/changehero/fixed", "changehero");
        }else if(sessionStorage.getItem("local_exchange_name")=="letsexchange" && sessionStorage.getItem("local_exchange_type")=="Fixed"){
            exchangeApiCall(process.env.REACT_APP_URL+"/createTransaction/letsexchange/fixed", "letsexchange");
        }else if(sessionStorage.getItem("local_exchange_name")=="stealthex" && sessionStorage.getItem("local_exchange_type")=="Fixed"){
            exchangeApiCall(process.env.REACT_APP_URL+"/createTransaction/stealthex/fixed", "stealthex");
        }
    }

    return(<>
    <ProgressInputWalletAddress progress={"deposit"}></ProgressInputWalletAddress>
    <div className='enter-address-text-div'>
        <div id='thumb-cursor' onClick={()=>{navigate("/best_crypto_to_crypto_exchange")}}><img className="img-fluid" src={backbtn}></img></div><span>Enter The </span> <span>Wallet Address</span>
    </div>
    <div className='container-fluid'>
        <div className='row'>
            {/* Recipient Wallet Address Input Box */}
            <div className='col-12 col-lg-6'>
                <div className='wallet-input-div'>
                    <div className='row recipient-amount-div'>
                        <div className='recipient-send-amount-div'><span>You Send:</span><span>{formatTo15Chars(sessionStorage.getItem("local_send_amount"), 15)}</span>
                            <div className='recipient-send-coin-logos'>
                                <img className='img-fluid' src={sessionStorage.getItem("local_send_crypto_logo")}></img>
                                <span>{sessionStorage.getItem("local_send_crypto_name")}</span>
                                <span className='chainname-deposit-page'>{sessionStorage.getItem("local_send_crypto_network")}</span>
                            </div>
                        </div>
                        <div className='grey-line-div'><img className='img-fluid' src={greydashedline}></img></div>
                        <div className='recipient-get-amount-div'><span>You Receive:</span><span>{formatTo15Chars(receiveAmount, 15)}</span>
                            <div className='recipient-get-coin-logos'>
                                <img className='img-fluid' src={sessionStorage.getItem("local_get_crypto_logo")}></img>
                                <span>{sessionStorage.getItem("local_get_crypto_name")}</span>
                                <span className='chainname-deposit-page'>{sessionStorage.getItem("local_get_crypto_network")}</span>
                            </div>
                        </div>
                    </div>          
                <div className='input-wrapper'><label className='recipient-input-label' for="first">Enter {sessionStorage.getItem("local_get_crypto_name")} <span class="chain-name-deposit">{sessionStorage.getItem("local_get_crypto_name")==sessionStorage.getItem("local_get_crypto_network")?"":sessionStorage.getItem("local_get_crypto_network")}</span> Address:</label><input type="text" className="form-control deposit-input" value={recipientaddress} onChange={(e)=>{setRecipientAddress(e.target.value.replace(/\s+/g, ''))}} placeholder="Recipient Address  .  .  ."></input><button class="btn paste-btn" onClick={async ()=>{setRecipientAddress(await handlePaste())}}>Paste</button> </div>
                <div className='validation-check' style={{display:recipientaddressvalidation?"block":"none"}}>{recipientaddressvalidationtext}</div>
                <div className='input-wrapper' style={{display:getextravisibility?"block":"none"}}><label className='recipient-input-label' for="first">Enter {sessionStorage.getItem("local_get_crypto_name")} Extra ID (Optional):</label><input type="text" className="form-control deposit-input" value={recipientextraid} onChange={(e)=>{setRecipientExtraId(e.target.value.replace(/\s+/g, ''))}} placeholder="Recipient Extra ID  .  .  ."></input><button class="btn paste-btn"  onClick={async()=>{ setRecipientExtraId(await handlePaste())}}>Paste</button> </div>
                <div className='input-wrapper'><label className='recipient-input-label' for="first">Enter {sessionStorage.getItem("local_send_crypto_name")} <span class="chain-name-deposit">{sessionStorage.getItem("local_send_crypto_name")==sessionStorage.getItem("local_send_crypto_network")?"":sessionStorage.getItem("local_send_crypto_network")}</span> Refund Address (Optional):</label><input type="text" className="form-control deposit-input" value={refundaddress} onChange={(e)=>{setRefundAddress(e.target.value.replace(/\s+/g, ''))}} placeholder="Refund Address  .  .  ."></input><button class="btn paste-btn" onClick={async()=>{setRefundAddress(await handlePaste())}}>Paste</button> </div>
                <div className='validation-check' style={{display:recipientrefaddressvalidation?"block":"none"}}>{recipientrefaddressvalidationtext}</div>
                <div className='input-wrapper' style={{display:refextravisibility?"block":"none"}}><label className='recipient-input-label' for="first">Enter {sessionStorage.getItem("local_send_crypto_name")} Refund Extra ID (Optional):</label><input type="text" className="form-control deposit-input" value={refundextraid} onChange={(e)=>{setRefundExtraId(e.target.value.replace(/\s+/g, ''))}}  placeholder="Refund Extra ID  .  .  ." ></input><button class="btn paste-btn" onClick={async()=>{setRefundExtraId(await handlePaste())}}>Paste</button> </div>
                <div className='input-wrapper'><label className='recipient-input-label' for="first">Enter Your Email Address (Optional):</label><input type="email" className="form-control deposit-input" id="email-input" value={email} onChange={(e)=>{setEmail(e.target.value)}} placeholder="Email Address  .  .  ."></input><button class="btn paste-btn" onClick={async ()=>{setEmail(await handlePaste())}}>Paste</button> </div>
                <div className='validation-check' style={{display:emailValidation?"none":"block", margin:"15px 0px"}}>Invalid email address</div>
                <div className='policy-check'><div><input className="form-check-input deposit-input" type="checkbox" value="" id="flexCheckChecked" onChange={(e)=>{setIsChecked(e.target.checked)}}></input></div><div><span>I Have Read And Accepted The</span><span><Link to="/terms_of_use" style={{color: "#f4a70c", textDecoration:"none"}}> Terms Of Use </Link></span><span> And</span><span><Link to="/privacy_policy" style={{color: "#f4a70c", textDecoration:"none"}}> Privacy Policy </Link></span></div></div>
                <div className='validation-check' style={{display:isCheckedValidation?"none":"block", margin:"15px 0px"}}>Please accept terms and conditions</div>
                <button type="button" className="btn proceed-exchange-btn"><div className='d-flex justify-content-center' onClick={()=>{setBtnText("Processing");performSwap()}}><div>{btntext}</div><div style={{display:btntext=="Proceed To Exchange"?"none":"block"}} className="loader"></div></div></button> 
                </div>
            </div>
            <div className='col-12 col-lg-6 exchange-partner-recipient-input-div'>
                <div className='row'>
                    <div className='exchange-partner-recipient-input'>
                        <span>Exchange Partner:</span>
                        <img src={sessionStorage.getItem("local_exchange_logo")}></img>
                    </div>
                    <div className='exchange-type-recipient-input'>
                        <span>Exchange Type:</span>
                        <div><img style={{filter:"brightness(2000%)"}} src={sessionStorage.getItem("local_exchange_type")=="Floating"?floatingicon:fixedicon}></img><span>{sessionStorage.getItem("local_exchange_type")}</span></div>
                    </div>
                </div>
                <div className='row eta-kyc-sec'>
                    <div className='exchange-partner-recipient-input'>
                        <span>ETA Time:</span>
                        <div className='deposit-eta'><img src={etaicon}></img><span> {sessionStorage.getItem('etaTime')}</span></div>  
                    </div>
                    <div className='exchange-partner-recipient-input' style={{marginLeft:"0px"}}>
                        <span>KYC Risk:</span>
                        <div className='deposit-kyc'><img src={sessionStorage.getItem('kyc')==="Rarely Required"?green:(sessionStorage.getItem('kyc')==="On Occasion"?yellow:(sessionStorage.getItem('kyc')==="Not Required"?darkgreen:red))}></img><span>{sessionStorage.getItem('kyc')}</span></div>
                    </div>
                </div>
                <div className='recipient-input-bullet-1'><div><i className="fa-solid fa-circle"></i></div><div><span>{sessionStorage.getItem("local_exchange_type")=="Floating"?"Floating Rates: ": "Fixed Rates: "}</span><span>{sessionStorage.getItem("local_exchange_type")=="Floating"?"You Have Selected A Floating Rate. Keep In Mind That The Final ": "You Have Selected A Fixed Rate. Keep In Mind The Allocated Time "}</span><span>{sessionStorage.getItem("local_exchange_type")=="Floating"?"Amount You Recieve May Vary Due To Fluctuations In Rates.": "To Deposit The Funds. "}</span></div></div>
                <div className='recipient-input-bullet-2' style={{display:sessionStorage.getItem("local_exchange_type")=="Floating"?"flex": "none"}}><div><i className="fa-solid fa-circle"></i></div><div><span>The Final Rate Is Determined By The Market Rate At The Time Of Completion Of Your Swap.</span></div></div>
                <div className='guide-div'><div><img className='img-fluid' src={guideicon}></img></div><div><span>Not Sure How To Use Our Service, Click On</span><span> <Link to="/how-it-works" className='howitworks-link'>"How It Works"</Link> </span><span>To Get A Tutorial Of The</span><span>Swap Process.</span></div></div>
            </div>
        </div>
    </div>

    <div className='recipient-input-how-to'><span>How To Swap Crypto</span><span>Coinoswap Is A Non-Custodial Crypto Exchange Aggregator Providing </span><span>Convenient And Speedy Exchanges To Swap Pairs.</span></div>
    <div className='three-and-four-step'>
        <div><img src={three}></img><div><span>Step 3</span><span>Provide The Wallet Address</span><span>Where You Want To Receive</span><span>Your Coins</span></div></div>
        <div><img className='img-fluid Horizontal_line' src={yellowline}></img><img className='img-fluid verticalLine' src={verticalline} style={{display:"none"}}></img></div>
        <div><img src={four}></img><div><span>Step 4</span><span>Send The Required Deposit</span><span>To The Designated</span><span>Address.</span></div></div>
    </div>
    </>);
}

export default Deposit;